import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import weekday from 'dayjs/plugin/weekday'
import "dayjs/locale/pl";

type SelectTimeProps = {
  reservationData?: object;
  setAllowNext: (value: boolean) => void;
  setReservationData: (value: object) => void;
};

export const SelectTime = ({
  reservationData,
  setAllowNext,
  setReservationData,
}: SelectTimeProps) => {
  const [dayPart, setDayPart] = useState(0);
  const [timeInput, setTimeInput] = useState("");

  useEffect(() => {
    if (reservationData?.time !=="") {
      setAllowNext(true);
    }
    return () => setAllowNext(false);
  }, [reservationData]);

  useEffect(() => {
    if(timeInput!==""){
      console.log(timeInput," :))")
      setReservationData((e: object) => ({ ...e, time: timeInput }));
    }
  }, [timeInput]);

  return (
    <StaticQuery
      query={graphql`
        query OpeningTimes {
          strapiContact {
            openingTimes {
              closeTime
              openTime
              day
            }
          }
        }
      `}
      render={({ strapiContact: { openingTimes } }) => {
        dayjs.extend(duration);
        dayjs.extend(weekday)
        const todayOpenTimes =
          // @ts-ignore
          openingTimes[dayjs(reservationData.date).weekday()];
        const openTime =
          (dayjs("1/1/1 " + todayOpenTimes.openTime).hour() - 1) *
          60;
        const closeTime =
          (dayjs("1/1/1 " + todayOpenTimes.closeTime).hour()) *
          60;



        console.log(openTime,closeTime," >>>",todayOpenTimes)

        const frames = [
          {
            name: "Poranek",
            start: openTime > 0 ? openTime : 0,
            end: closeTime < 705 ? closeTime : 705,
          },
          {
            name: "Południe",
            start: openTime > 720 ? openTime : 720,
            end: closeTime < 1125 ? closeTime : 1125,
          },
          {
            name: "Wieczór",
            start: openTime > 1140 ? openTime : 1140,
            end: closeTime < 1440 ? closeTime : 1440,
          },
        ];

        console.log(frames," FRAMERS")

        let time = 0;
        let render = [];
        do {
          time += 15;
          frames
            .filter((_, key) => key === dayPart)
            .every(({ start, end, name }) => {
              if (start <= time && end >= time) {
                const hours = Math.floor(time / 60);
               const ansH =  (hours < 10 ? '0' : '') +hours
                const minutesCalc = time % 60;
                const minutes = minutesCalc === 0 ? "00" : minutesCalc;
                const ans = ansH + ":" + minutes;
                render.push(
                  <button
                    className={`selectTime__times__time${
                      reservationData?.time === ans ? "--active" : ""
                    }`}
                    onClick={() => setTimeInput(ans)}
                  >
                    {ans}
                  </button>
                );
                return false;
              }
              return true;
            });
          // console.log(time);
        } while (time < 1440);

        return (
          <>
            <div className={"selectTime__menu"}>
              {frames.map(({ name }, key) => {
                return (
                  <button
                    className={`selectTime__menuItem${
                      key === dayPart ? "--active" : ""
                    }`}
                    key={key}
                    onClick={() => setDayPart(key)}
                  >
                    {name}
                  </button>
                );
              })}
            </div>
            <div className={"selectTime__times"}>{render}</div>
          </>
        );
      }}
    />
  );
};
